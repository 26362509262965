@import "./variables.scss";

.hero{
  .header-space{
    width: 100%;
    height: 96px;
    @include mediaM(750px) {
      height: 70px;
    }
  }
  .hero-spread{
    position: relative;
    width: 90%;
    margin: auto;
    max-width: 1200px;
    min-height: calc(100vh - 96px);
    @include mediaM(750px) {
      min-height: calc(100vh - 70px);
    }
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    .star-bg{
      position: absolute;
      width: 100px;
      animation: star-spin 10s ease 0s infinite;
      animation-fill-mode: both;
      @include mediaM(850px) {
        width: 60px;
      }
      img{
        width: 100%;
      }
    }
    .top-left{
      top: 4%;
      left: 0;
    }
    .top-right{
      top: 4%;
      right: 0;
      animation-delay: 2s;
    }
    .bottom-left{
      bottom: 4%;
      left: 0;
      animation-delay: 2s;
    }
    .bottom-right{
      bottom: 4%;
      right: 0;
    }
    .center-star{
      top: calc(50% - 50px);
      left: calc(50% - 50px);
      @include mediaM(850px) {
        display: none;
      }
      animation-delay: 3.5s;
    }
    .img-sect{
      @include mediaM(750px) {
        max-width: 70vw;
        margin-bottom: 3em;
      }
      img{
        width: 100%;
      }
    }
    .text-sect{
      max-width: 450px;
      position: relative;
      @include mediaM(950px) {
        max-width: 380px;
      }
      .hollow-bg{
        width: 160%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img{
          width: 100%;
        }
      }
      h1{
        font-weight: 700;
      }
    }
    @include mediaM(750px) {
      flex-direction: column;
      justify-content: center;
      .logo-sect{
        width: 80%;
        margin: 0 auto 4em;
        max-width: 350px;
        img{
          width: 100%;
        }
      }
      .text-sect{
        text-align: center;
        max-width: 450px;
        h1{
          font-size: 2em;
        }
      }
    }
  }
}

.about{
  position: relative;
  background-image: linear-gradient( #ffffff, #ffffff, #EBFBFF, #EBFBFF, #EBFBFF, #EBFBFF, #EBFBFF, #EBFBFF, #EBFBFF, #EBFBFF, #ffffff );
  .about-card{
    padding: 10px 15px;
    border: 1px dashed $primary-blue-hover;
    border-radius: 20px;
    background-color: $white-faint2;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.06);
  }
}

.info{
  position: relative;
  .bg-curve{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: calc(50% - 6vw);
    img{
      width: 100%;
    }
  }
  .bg-fill{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: calc(50% - 6vw);
    background-color: $primary-blue-bright;
  }
  .cv-sect{
    width: 100%;
    padding: 3em 5px;
    border-top: 1px dashed $primary-blue-hover;
    border-bottom: 1px dashed $primary-blue-hover;
  }
  .switcher{
    width: 80%;
    max-width: 360px;
    display: grid;
    grid-template-columns: 50% 50%;
    border: 1px solid $primary-blue-hover;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    p{
      margin: 6px 0;
    }
    .websites{
      border-radius: 10px 0 0 10px;
    }
    .prototypes{
      border-radius: 0 10px 10px 0;
    }
    .active{
      background-color: $primary-blue-hover;
      transition: 0.3s ease;
      box-shadow: 0 3px 10px 0 $black-faint3;
      pointer-events: none;
      p{
        color: $white;
        font-weight: 700;
      }
    }
    .dormant{
      color: $primary-blue-hover;
      transition: 0.3s ease;
      cursor: pointer;
    }
  }
  .portfolio-card{
    position: relative;
    border: 1px dashed $primary-blue-hover;
    background-color: $white-faint1;
    padding: 10px;
    width: 90%;
    margin: 1.5em auto;
    max-width: 300px;
    border-radius: 20px;
    text-align: center;
    img{
      width: 100%;
      border-radius: 10px;
      border: 1px solid rgba(0, 102, 219, 0.2);
    }
    button{
      width: 100%;
    }
  }
  .online-links{
    background-color: $primary-blue-bright;
    .link-card{
      position: relative;
      border: 1px dashed $primary-blue-hover;
      background-color: $white-faint1;
      box-shadow: 0 2px 10px 0 $black-faint3;
      padding: 15px 10px;
      width: 96%;
      margin: auto;
      max-width: 300px;
      border-radius: 15px;
      text-align: center;
      @include mediaW(768px){
        margin: 0.7em auto;
      }
    }
  }
}

.vid-modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
  opacity: 0;
  .modal-bg{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $black-faint2;       
  }
  .modal-container{
      width: 90%;
      max-width: 700px;
      position: relative;
      .modal-content{
          position: relative;
          width: 100%;
          border-radius: 15px;
          height: 0;
          padding-bottom: 56.25%;
          background-color: $white;
          transition: 0.4s ease;
          transform: scale(0.001);
          .stream-vid{
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              border-radius: 15px;
              overflow: hidden;
          }
          .modal-closer{
              position: absolute;
              top: - 40px;
              right: -10px;
              border-radius: 25%;
              opacity: 0.5;
              background-color: $red;
              color: $white;
              padding: 3px 10px;
              transition: 0.2s ease;
              @include mediaM(500px) {
                  padding: 4px 8px 3px;
              }
              &:hover{
                  background-color: $red-hover;
                  opacity: 1;
              }
              &:active{
                  transform: scale(1.1);
                  background-color: $red-active;
              }
          }
      }
  }
}


@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
@keyframes spin-trans-center {
  0% {transform: translate(50%, 50%) rotate(0deg);}
  100% {transform: translate(50%, 50%) rotate(360deg);}
}




#leaves {position:relative;top:-50px;width:100%;text-align: right;}

#leaves i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background: linear-gradient(to bottom right, #309900, #005600);
    transform: skew(20deg);
    border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333;
    z-index: 1;
    animation: falling 5s 0s infinite;
}

#leaves i:nth-of-type(2n) { animation: falling2 5s 0s infinite; }
#leaves i:nth-of-type(3n) { animation: falling3 5s 0s infinite; }

#leaves i:before {
  position: absolute;
  content: '';
  top: 117px;
  right: 9px;
  height: 27px;
  width: 32px;
  transform: rotate(49deg);
  border-radius: 0% 15% 15% 0%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 0px solid #222;
  border-right: 1px solid #222;
  background: linear-gradient(to right, rgba(0,100,0,1), #005600);
  z-index: 1;
}

#leaves i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0,0,0,.15), rgba(0,0,0,0));
  display: block;
  transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius:50%;
}


#leaves i:nth-of-type(n)    { height:23px; width:30px; }
#leaves i:nth-of-type(n):before { width:7px; height:5px; top:17px; right:1px; }
#leaves i:nth-of-type(n):after { width:2px; height:17px; left: 12px; top:0px; }

#leaves i:nth-of-type(2n+1)    { height:11px; width:16px; }
#leaves i:nth-of-type(2n+1):before { width:4px; height:3px; top:7px; right:0px; }
#leaves i:nth-of-type(2n+1):after { width:2px; height:6px; left: 5px; top:1px; }

#leaves i:nth-of-type(3n+2)  { height:17px; width:23px; }
#leaves i:nth-of-type(3n+2):before  { height:4px; width:4px; top:12px; right:1px; }
#leaves i:nth-of-type(3n+2):after  { height:10px; width:2px; top:1px; left:8px; }

#leaves i:nth-of-type(n)   { animation-delay: 1.9s;}
#leaves i:nth-of-type(2n)  { animation-delay: 3.9s;}
#leaves i:nth-of-type(3n)  { animation-delay: 2.3s;}
#leaves i:nth-of-type(4n)  { animation-delay: 4.4s;}
#leaves i:nth-of-type(5n)  { animation-delay: 5s;  }
#leaves i:nth-of-type(6n)  { animation-delay: 3.5s;}
#leaves i:nth-of-type(7n)  { animation-delay: 2.8s;}
#leaves i:nth-of-type(8n)  { animation-delay: 1.5s;}
#leaves i:nth-of-type(9n)  { animation-delay: 3.3s;}
#leaves i:nth-of-type(10n) { animation-delay: 2.5s;}
#leaves i:nth-of-type(11n) { animation-delay: 1.2s;}
#leaves i:nth-of-type(12n) { animation-delay: 4.1s;}
#leaves i:nth-of-type(13n) { animation-delay: 1s;  }
#leaves i:nth-of-type(14n) { animation-delay: 4.7s;}
#leaves i:nth-of-type(15n) { animation-delay: 3s;  }

#leaves i:nth-of-type(n)    { background: linear-gradient(to bottom right, #309900, #005600); }
#leaves i:nth-of-type(2n+2)  { background: linear-gradient(to bottom right, #5e9900, #2b5600); }
#leaves i:nth-of-type(4n+1)  { background: linear-gradient(to bottom right, #990, #564500); }

#leaves i:nth-of-type(n)    { opacity: .7;}
#leaves i:nth-of-type(3n+1)  { opacity: .5;}
#leaves i:nth-of-type(3n+2)  { opacity: .3;}

#leaves i:nth-of-type(n)    {transform: rotate(180deg);}


#leaves i:nth-of-type(n) { animation-timing-function:ease-in-out;}

@keyframes falling {
    
    0% {
        transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        transform:
            translate3d(-350px,700px,0)
            rotate(90deg);
        opacity: 0;
    }
}

@keyframes falling3 {
     0% {
        transform:
            translate3d(0,0,0)
            rotate(-20deg);
    }
    
    100% {
        transform:
            translate3d(-230px,640px,0)
            rotate(-70deg);
        opacity: 0;
    }
}

@keyframes falling2 {
     0% {
        transform:
            translate3d(0,0,0)
            rotate(90deg);
    }
    
    100% {
        transform:
            translate3d(-400px,680px,0)
            rotate(0deg);
        opacity: 0;
    }
}



@keyframes star-spin {
  0% { opacity: 0; transform: rotate(0deg) scale(0); }
  5% { opacity: 0.5; transform: rotate(0deg) scale(0); }
  10% { opacity: 1; transform: rotate(0deg) scale(1); }
  30% { opacity: 1; transform: rotate(700deg) scale(1); }
  35% { opacity: 0; transform: rotate(900deg) scale(1.5); }
  55% { opacity: 0; transform: rotate(900deg) scale(1.5); }
  60% { opacity: 1; transform: rotate(720deg) scale(1); }
  80% { opacity: 1; transform: rotate(0deg) scale(1); }
  100% { opacity: 0; transform: rotate(0deg); }
}