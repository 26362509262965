// @font-face {
//   font-family: "Avenir";
//   src: url(assets/fonts/AvenirLTStd-Book.ttf);
// }
// @font-face {
//   font-family: "Avenir medium";
//   src: url(assets/fonts/AvenirLTStd-Medium.ttf);
// }
// @font-face {
//   font-family: "Avenir bold";
//   src: url(assets/fonts/AvenirLTStd-Heavy.ttf);
// }
@font-face {
  font-family: "Ethnocentric";
  src: url(assets/fonts/ethnocentric-rg.ttf);
}


// * {
//   font-family: "Avenir";
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
//   @include mediaW(850px) {
//     font-size: 18px;
//   }
//   @include mediaM(750px) {
//     font-size: 0.9rem;
//   }
// }

@import "./assets/styles/variables.scss";

.special-font{
  font-family: "Ethnocentric";
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat';
  color: $primary-blue-hover;
  @include mediaM(750px){
      font-size: 0.9rem;
  }
}
a {
  color: inherit;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    color: inherit;
  }
}

#root {
  width: 100%;
  overflow-x: hidden;
}

h6 {
  font-family: "Avenir bold";
  font-weight: 900;
}

h3 {
  font-family: "Avenir black";
  font-weight: 900;
}
.selected-nav {
  color: $primary-blue;
  opacity: 1;
  font-weight: 900;
  font-family: "Avenir black";
  span {
    color: $primary-blue;
    opacity: 1;
    font-family: "Avenir black";
    font-weight: 900;
  }
  button{
    color: $black;
  }
}
