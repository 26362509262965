@import "./variables.scss";

.header {
  transition: 0.3s ease;
  padding: 15px 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  .sub-layer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.09);
    border-bottom: 1px dashed $primary-blue-hover;
  }
  .spread-nav-web {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mediaM(660px) {
      display: block;
    }
  }
  .logo {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    img {
      width: 100%;
    }
  }
  .logo-mini {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 9px;
    // box-shadow: 0 0 10px 1px $white-faint1;
    // background-color: $black-faint1;
    img {
      width: 100%;
    }
  }
  .mobile-menu {
    width: 100px;
    text-align: right;
    i {
      font-size: 2em;
      color: $black
    }
  }
  .mobile-menu2 {
    i {
      font-size: 2em;
    }
  }

  .subscribe-grid{
    width: 400px;
  }
  .nav {
    display: block;
    transition: 0.3s ease;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 5px 11px;
        font-size: 0.9em;
        transition: 0.2s;
        @include mediaM(750px){
          padding-top: 20px;
        }
        &:hover {
          transform: scale(1.1);
        }
        button{
          padding: 10px 20px;
          // border-radius: 30px;
          // background-image: linear-gradient(to right, #E7324E, #BA3A7C);
          border: none;
          &:focus{
            outline: none;
          }
        }
        span {
          color: $black;
          padding: 10px 5px;
          display: block;
          font-size: 1em;
          font-weight: 500;
          transition: 0.3s ease;
          i {
            transform: translateY(2px);
          }
        }
        .service-drop-down{
          overflow: hidden;
          height: 0;
          opacity: 0;
          border-radius: 10px;
          transition: 0.3s ease;
          @include mediaW(661px){
            position: absolute;
            box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.3);
            padding: 12px 10px;
            background-color: $white;
            transform: translateX(-50%);
            left: 50%;
            width: 90vw;
            max-width: 210px;
            font-size: 0.9em;
          }
          @include mediaM(660px){
            box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.1);
            padding: 6px;
            background-color: $white-faint2;
          }
        }
        .handler:hover{
          .service-drop-down{
            height: auto;
            opacity: 1;
          }
        }
      }
    }
    .full-half{
      color: $primary-pale;
      background-color: $white;
      border-radius: 20px;
      padding: 5px 20px;
      border: 0.5px solid $black-faint4;
    }
  }
  @include mediaM(660px) {
    padding: 0;
    .nav {
      ul {
        li {
          display: block;
        }
        padding-bottom: 15px;
      }
    }
  }
}

.header-fill{
  width: 100%;
  height: 100px;
  @include mediaM(660px) {
    height: 78px;
  }
}

.mobile-notification{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  color: $black-faint1;
  background-color: $white;
  border-radius: 20px;
  border: 0.5px solid $black-faint4;
}
.mobile-user-menu{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  background-color: $primary-pale;
  color: $white;
  border-radius: 20px;
  .draw-holder, .draw-holder-mobile-user{
    color: $black;
  }
}

.search-hover-button{
  position: relative;
  background-color: $primary-pale;
  color: $white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 9;
  .holder{
      position: absolute;
      top: 130%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: 0.3s ease;
  }
  &:hover{
      .holder{
          opacity: 1;
          pointer-events: all;
          transform: translate(-50%, 0);
      }
  }
}
.search-holder{
  position: relative;
  z-index: 9;
  .search-button{
    background-color: $primary-pale;
    color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  .holder{
      position: absolute;
      top: 130%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      opacity: 0;
      transition: 0.3s ease;
  }
  // &:hover{
  //     .holder{
  //         opacity: 1;
  //         pointer-events: all;
  //         transform: translate(-50%, 0);
  //     }
  // }
  .active-holder{
    opacity: 1;
    pointer-events: all;
    transform: translate(-50%, 0);
  }
}

.subscribe-grid{
    display: grid;
    grid-template-columns: auto 70px;
    border-radius: 30px;
    box-shadow: 0 0 8px 1px $black-faint4;
    border: 0.5px solid $black-faint4;
    max-width: 80vw;
    overflow: hidden;
    // border: 1px solid $white-faint4;
    background-color: $white-faint4;
    padding: 8px;
    input, button { 
        height: 100%;
        border: none;
        border-radius: 10px;
    }
    input{
        padding: 12px 15px;
        background-color: $primary-blue-hover-faint4;
        // border-radius: 10px 0 0 10px;
        border: 1px solid $white-faint2;
        color: $white;
        border-radius: 20px 10px 10px 20px;
        &:focus{
            outline: none;
        }
    }
    button{
        background-color: $primary-blue;
        color: $white;
        transition: 0.3s ease;
        cursor: pointer;
        border-radius: 10px 20px 20px 10px;
        &:hover{
            background-color: $primary-blue-hover;
        }
        &:active{
            background-color: $primary-blue-active;
        }
        &:focus{
          outline: none;
        }
    }
}
.inc-grid{
  grid-template-columns: auto 10px 140px;
  @include mediaM(750px) {
    grid-template-columns: auto 9px 100px;
  }
  @include mediaM(500px) {
    grid-template-columns: auto 8px 86px;
  }
}
.click-catcher-obj{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.open-mobile-menu {
  @include mediaM(660px) {
    background-color: $black;
    .nav {
      max-height: 200vh;
    }
  }
}


.close-mobile-menu {
  .nav {
    @include mediaM(660px) {
      max-height: 0;
      overflow: hidden;
    }
  }
}


.footer {
  background-color: #F9FEFF;
  color: $primary-blue-hover;
  p{
    color: $primary-blue-hover;
  }
}